<template>
  <div class="login">
    <div class="box">
      <div class="banner">
        <img src="../assets/images/banner.png" alt="">
      </div>
      <div class="detail">
        <!-- 登录 -->
        <div v-if="signUpStatu == 0">
          <p class="title">DECADES收银系统</p>
          <div class="btn">
            <p class="account" :class="{phone:loginType == 0}" @click="loginClick(0)">账户登录</p>
            <p class="account" :class="{phone:loginType == 1}" style="margin-left: 8rem;" @click="loginClick(1)">手机号登录</p>
          </div>
          <!-- 账户登录 -->
          <div v-if="loginType == 0">
            <div class="input">
              <img class="input-img" src="../assets/images/accountIcon.png" alt="">
              <input v-model="username" type="text" placeholder="请输入用户名">
            </div>
            <div class="input" style="position:relative;">
              <img class="input-img" style="width:13rem;" src="../assets/images/passwordIcon.png" alt="">
              <input v-model="password" type="password" placeholder="请输入密码">
              <!-- <p style="position:absolute;font-size:14rem;color:#FF0000;left:0;top:40rem;">密码错误</p> -->
            </div>
            <p style="margin-top:32rem;font-size:14rem;color:#296B57;margin-left:240rem;" @click="forgetClick(1)">忘记密码</p>
            <p class="signIn" style="margin-top:8rem;" @click="loginAccount()">登录</p>
          </div>
          <!-- 手机号登录 -->
          <div v-if="loginType == 1">
            <div class="input">
              <img class="input-img" src="../assets/images/phoneIcon.png" alt="">
              <input v-model="phone" type="number" placeholder="请输入手机号">
            </div>
            <div class="input">
              <img class="input-img" style="width:13rem;" src="../assets/images/codeIcon.png" alt="">
              <input v-model="phoneCode" type="number" style="width:160rem;" placeholder="请输入验证码">
              <p class="msg" v-show="show" @click="sendCode()">发送验证码</p>
              <p class="msg" v-show="!show">{{count}} 秒</p>
            </div>
            <p class="signIn" @click="loginPhone()">登录</p>
          </div>
        </div>
        <!-- 找回密码 -->
        <div class="retrieve" v-if="signUpStatu == 1">
          <img class="retrieve-return" @click="forgetClick(0)" src="../assets/images/returnIcon.png" alt="">
          <p class="retrieve-title">DECADES收银系统</p>
          <div v-if="retrieve == 0">
            <p class="retrieve-title" style="padding-bottom:15rem;">手机找回密码</p>
            <div class="input">
              <img class="input-img" src="../assets/images/phoneIcon.png" alt="">
              <input v-model="phone" type="number" placeholder="请输入手机号">
            </div>
            <div class="input">
              <img class="input-img" style="width:13rem;" src="../assets/images/codeIcon.png" alt="">
              <input type="number" v-model="phoneCode" style="width:160rem;" placeholder="请输入验证码">
              <p class="msg" v-show="show" @click="sendCode()">发送验证码</p>
              <p class="msg" v-show="!show">{{count}} 秒</p>
            </div>
            <p class="signIn" style="margin-top:84rem;" @click="checkCode()">下一步</p>
          </div>
          <div v-if="retrieve == 1">
            <p class="retrieve-title" style="padding-bottom:15rem;">设置密码</p>
            <div class="input">
              <img class="input-img" src="../assets/images/codeIcon.png" alt="">
              <input v-model="password" type="text" placeholder="请设置密码">
            </div>
            <div class="input">
              <img class="input-img" src="../assets/images/codeIcon.png" alt="">
              <input v-model="confirmPassword" type="text" placeholder="请设置密码">
            </div>
            <p class="signIn" style="margin-top:84rem;" @click="modifyClick()">确定</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login, smscheckcode, checkCodeLogin, checkCode, updatePwd } from '../until/user.js'
export default {
  props: {

  },
  components: {

  },
  data () {
    return {
      loginType: 0,
      signUpStatu: 0,
      retrieve: 0,
      // 账号密码
      password: '',
      username: '',
      // 手机号验证码
      phone: '',
      phoneCode: '',
      // 倒计时
      show: true,
      count: '',
      timer: null,
      // 确认密码
      confirmPassword: ''
    }
  },
  computed: {

  },
  created () {

  },
  mounted () {
    if (localStorage.eToken) {
      this.$router.push('/home')
    }
  },
  methods: {
    loginClick (type) {
      this.loginType = type
    },
    // 用户名密码登录
    loginAccount () {
      if (this.username === '') {
        this.$message({
          message: '请输入用户名',
          type: 'warning'
        })
        return
      }
      if (this.password === '') {
        this.$message({
          message: '请输入密码',
          type: 'warning'
        })
        return
      }
      var data = {
        password: this.password,
        username: this.username
      }
      return new Promise((resolve, reject) => {
        login(data)
          .then(response => {
            if (response.data.code === 200) {
              localStorage.setItem('eToken', response.data.data)
              this.$router.go('/home')
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 发送验证码
    sendCode () {
      if (this.phone === '') {
        this.$message({
          message: '请输入手机号',
          type: 'warning'
        })
        return
      }
      const a = /^1\d{10}$/
      if (!(a.test(this.phone))) {
        this.$message({
          message: '手机号格式不正确',
          type: 'warning'
        })
        return
      }
      return new Promise((resolve, reject) => {
        smscheckcode(this.phone)
          .then(response => {
            if (response.data.code === 200) {
              this.$message({
                showClose: true,
                message: '验证码发送成功',
                type: 'success'
              })
              const TIME_COUNT = 60
              if (!this.timer) {
                this.count = TIME_COUNT
                this.show = false
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--
                  } else {
                    this.show = true
                    clearInterval(this.timer)
                    this.timer = null
                  }
                }, 1000)
              }
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 手机号验证码登录
    loginPhone () {
      if (this.phone === '') {
        this.$message({
          message: '请输入手机号',
          type: 'warning'
        })
        return
      }
      const a = /^1\d{10}$/
      if (!(a.test(this.phone))) {
        this.$message({
          message: '手机号格式不正确',
          type: 'warning'
        })
        return
      }
      if (this.phoneCode === '') {
        this.$message({
          message: '请输入验证码',
          type: 'warning'
        })
        return
      }
      return new Promise((resolve, reject) => {
        checkCodeLogin(this.phoneCode, this.phone)
          .then(response => {
            if (response.data.code === 200) {
              localStorage.setItem('eToken', response.data.data)
              this.$router.go('/home')
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 忘记密码
    forgetClick (type) {
      this.signUpStatu = type
      // 账号密码
      this.password = ''
      this.username = ''
      // 手机号验证码
      this.phone = ''
      this.phoneCode = ''
    },
    // 校验验证码
    checkCode () {
      if (this.phone === '') {
        this.$message({
          message: '请输入手机号',
          type: 'warning'
        })
        return
      }
      const a = /^1\d{10}$/
      if (!(a.test(this.phone))) {
        this.$message({
          message: '手机号格式不正确',
          type: 'warning'
        })
        return
      }
      if (this.phoneCode === '') {
        this.$message({
          message: '请输入验证码',
          type: 'warning'
        })
        return
      }
      return new Promise((resolve, reject) => {
        checkCode(this.phoneCode, this.phone)
          .then(response => {
            if (response.data.code === 200) {
              this.retrieve = 1
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 修改密码
    modifyClick () {
      if (this.password === '') {
        this.$message({
          message: '请输入密码',
          type: 'warning'
        })
        return
      }
      if (this.confirmPassword === '') {
        this.$message({
          message: '请再次输入密码',
          type: 'warning'
        })
        return
      }
      if (this.confirmPassword !== this.password) {
        this.$message({
          message: '两次密码输入不一致',
          type: 'warning'
        })
        return
      }
      return new Promise((resolve, reject) => {
        updatePwd(this.confirmPassword, this.password, this.phone)
          .then(response => {
            if (response.data.code === 200) {
              this.$message({
                showClose: true,
                message: '密码修改成功',
                type: 'success'
              })
              this.signUpStatu = 0
              this.retrieve = 0
              this.password = ''
              this.confirmPassword = ''
            } else {
              this.$message({
                message: response.data.msg,
                type: 'warning'
              })
              return
            }
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  }
}
</script>

<style scoped lang="less">
  .login{
    width: 100%;
    height: 100%;
    position: fixed;
    background: #00281C;
    top: 0;
    left: 0;
    z-index: 10001;
    .box{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 115rem;
      .banner{
        width: 640rem;
        height: 556rem;
        background: #F0F2F5;
        border-radius: 10rem 0 0 10rem;
        img{
          width: 596rem;
          height: 320rem;
          display: block;
          margin: 120rem auto 0 auto;
        }
      }
      .detail{
        width: 420rem;
        height: 556rem;
        border-radius: 0 10rem 10rem 0;
        background: #FFFFFF;
        .title{
          padding-top: 100rem;
          margin-left: 60rem;
          font-size: 20rem;
          color: #00281C;
          line-height: 28rem;
          text-align: left;
        }
        .btn{
          display: flex;
          justify-content: center;
          margin-top: 30rem;
          margin-bottom: 6rem;
          .account{
            width: 145rem;
            height: 43rem;
            border-radius: 4rem;
            background: #F0F2F5;
            text-align: center;
            line-height: 43rem;
            font-size: 16rem;
            color: #00281C;
          }
          .phone{
            width: 145rem;
            height: 43rem;
            border-radius: 4rem;
            background: #00281C;
            text-align: center;
            line-height: 43rem;
            font-size: 16rem;
            color: #fff;
            transition-duration: 0.5s;
          }
        }
        .input{
          width: 292rem;
          border-bottom: 1rem solid #00281C;
          margin: 30rem auto 0 auto;
          display: flex;
          align-items: center;
          padding-bottom: 14rem;
          .input-img{
            width: 11rem;
            height: 14rem;
          }
          input{
            outline: none;
            font-size: 16rem;
            margin-left: 12rem;
            border: none;
            flex: 1;
          }
          .msg{
            font-size: 16rem;
            color: #296B57;
            border-bottom: 1rem solid #296B57;
          }
        }
        .signIn{
          width: 298rem;
          height: 50rem;
          margin: 58rem auto 0 auto;
          border-radius: 4rem;
          background: #00281C;
          text-align: center;
          line-height: 50rem;
          font-size: 18rem;
          color: #fff;
        }
      }
      // 找回密码
      .retrieve{
        .retrieve-return{
          width: 21rem;
          height: 19rem;
          margin: 63rem 0 7rem 62rem;
          display: block;
        }
        .retrieve-title{
          font-size: 20rem;
          color: #00281C;
          margin: 0 0 0 61rem;
          line-height: 28rem;
          text-align: left;
          padding-top: 12rem;
        }
      }
    }
  }
</style>
